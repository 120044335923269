.connexion {
  color: var(--global-blue-color);
  text-align: center;
  background: url("../assets/fonds/image_connexion_leco.webp");
  background-color: beige;
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    background-color: white;
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    width: fit-content;
    height: fit-content;
    padding-right: 2.5rem;
    border-radius: 1rem;
  }

  .title {
    margin: 1.5rem 0rem;
    font-size: var(--title-size);
    font-weight: var(--title-weight);
    display: flex;
    justify-content: center;
  }

  .copyright {
    margin-bottom: 1rem;
    font-size: 0.8rem;
    color: lightgrey;
  }

  .perte-id {
    margin-bottom: 2rem;
  }

  .text-item {
    display: block;
  }

  .img-connexion {
    width: 2.5rem;
    padding: 0;
    cursor: pointer;
  }

  .img-connexion:hover {
    opacity: 0.5;
  }

  .img-content {
    display: flex;
    padding: 1.5rem 0px 0px 0px;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .connexion-label {
    display: block;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 200;
  }

  .connexion-item {
    display: flex;

    width: fit-content;
    margin: 0.5rem 2rem;
    justify-content: space-between;
    gap: 1rem;
    margin-right: -1rem;
    margin-bottom: 1rem;
  }

  .connexion-item .col {
    margin: auto;
  }

  .input-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    input {
      text-align: center;
      border-color: #f3b540;
    }
    .form-control:focus {
      box-shadow: 0 0 0 0.25rem #f3b5402b;
    }
  }
}

@media (max-width: 575px) {
  .connexion {
    padding-right: 4%;
    padding-left: 4%;

    .title {
      margin-top: 2rem;
      font-size: 2.5rem;
      padding-left: 0;
      margin-bottom: 0rem;
    }

    .subtitle {
      margin-top: 0rem;
      font-size: 1.5rem;
    }

    .connexion-item {
      flex-direction: column;
      display: flex;
      width: -moz-fit-content;
      width: fit-content;
      justify-content: space-between;
      gap: 1rem;
      margin: auto;
      margin-top: 1rem;
    }

    .img-content {
      padding: 0px;
    }

    .content {
      padding: 0rem 2.5rem;
    }
  }
}
